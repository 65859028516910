<template>
  <b-card>
    <b-card-title style="font-size: xx-large; text-align: center;">
      Audit Info
    </b-card-title>
    <b-card-text><strong>Description: </strong>{{ auditData.description }}</b-card-text>
    <b-card-text><strong>Days Required: </strong>{{ auditData.days_require }}</b-card-text>
    <b-card-text><strong>Location: </strong>{{ auditData.location }}</b-card-text>
    <b-card-text><strong>Cost: </strong>{{ auditData.cost }}</b-card-text>
    <b-card-text><strong>Price: </strong>{{ auditData.price }}</b-card-text>

    <b-card-title style="font-size: xx-large;">
      Request Info
    </b-card-title>
    <b-card-text v-if="auditData.request">
      <strong>Request Code: </strong>{{ auditData.request.request_code }}
    </b-card-text>
    <b-card-text v-if="auditData.request">
      <strong>Request Status: </strong>{{ auditData.request.status }}
    </b-card-text>
    <b-card-text v-if="auditData.request">
      <strong>Source: </strong>{{ auditData.request.source }}
    </b-card-text>
    <b-card-text v-if="auditData.request">
      <strong>Certificate Name: </strong>{{ auditData.request.certificate_name.en }}
    </b-card-text>
    <b-card-text v-if="auditData.request">
      <strong>Certificate Name Arabic: </strong>{{ auditData.request.certificate_name.ar }}
    </b-card-text>
    <b-card-text v-if="auditData.request">
      <strong>Payment Status: </strong>{{ auditData.request.payment_status }}
    </b-card-text>
    <b-card-text v-if="auditData.request">
      <strong>Final Price: </strong>{{ auditData.request.final_price }}
    </b-card-text>

    <b-card-title style="font-size: xx-large;">
      Requester Info
    </b-card-title>
    <b-card-text v-if="auditData.requester">
      <strong>Name: </strong>{{ auditData.requester.name }}
    </b-card-text>
    <b-card-text v-if="auditData.requester">
      <strong>Email: </strong>{{ auditData.requester.email }}
    </b-card-text>
    <b-card-text v-if="auditData.requester">
      <strong>Wallet Balance: </strong>{{ auditData.requester.wallet_balance }}
    </b-card-text>
    <b-card-title style="font-size: xx-large;">
      Engineers List
    </b-card-title>
    <div class="d-flex flex-wrap">
      <div
        v-for="engineer in engineersData"
        :key="engineer.id"
        style="border: 1px solid; padding: 15px; margin: 5px; display: flex; flex-direction: column; width: fit-content;"
      >
        <b-card-text><strong>Name: </strong>{{ engineer.name }}</b-card-text>
        <b-card-text><strong>Email: </strong>{{ engineer.email }}</b-card-text>
        <b-card-text><strong>Department: </strong>{{ engineer.department_name }}</b-card-text>
        <b-card-text><strong>Job Description: </strong>{{ engineer.job_description }}</b-card-text>
        <b-card-text><strong>Joined At: </strong>{{ engineer.joined_at }}</b-card-text>
        <b-card-text><strong>Load: </strong>{{ engineer.load }}</b-card-text>

        <b-card-text><strong>Office: </strong>{{ engineer.office.name }},
          {{ engineer.office.country }}</b-card-text>
        <b-card-text><strong>Statuses: </strong>
          <ul>
            <li
              v-for="status in engineer.status"
              :key="status.id"
            >
              {{ status.status }}
            </li>
          </ul>
        </b-card-text>
        <b-card-text><strong>Certificates: </strong>
          <ul>
            <li
              v-for="certificate in engineer.certificates"
              :key="certificate.id"
            >
              {{ certificate.name.en }} ({{ certificate.name.ar }})
            </li>
          </ul>
        </b-card-text>
      </div>
    </div>
  </b-card>
</template>

<script>
import axios from 'axios'
import decryptData from '@/store/auth/decryptData'

export default {
  data() {
    return {
      auditData: {
        description: '',
        days_require: '',
        location: '',
        cost: '',
        price: '',
        request: {
          request_code: '',
          status: '',
          source: '',
          certificate_name: { en: '', ar: '' },
          payment_status: '',
          final_price: '',
        },
        requester: {
          name: '',
          email: '',
          wallet_balance: '',
        },
      },
      engineersData: [],
    }
  },
  mounted() {
    this.showAudit()
  },
  methods: {
    showAudit() {
      if (this.$route.params.id) {
        axios
          .get(`/audit/${this.$route.params.id}`)
          .then(res => {
            this.auditData = decryptData(res.data.payload).audit
            this.engineersData = decryptData(res.data.payload).audit.engineers
          })
          // .catch(error => {
          //   console.log(error)
          // })
      }
    },
  },
}
</script>

<style scoped></style>
